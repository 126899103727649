/*Fonts*/

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/jost.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/jost.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/jost.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*Basic styles and layout*/

:root {
  --fs-50: clamp(0.8333rem, 0.8303rem + 0.0152vi, 0.844rem);
  --fs-100: clamp(1rem, 0.9643rem + 0.1786vi, 1.125rem);
  --fs-200: clamp(1.2rem, 1.1144rem + 0.428vi, 1.4996rem);
  --fs-300: clamp(1.44rem, 1.2803rem + 0.7986vi, 1.999rem);
  --fs-400: clamp(1.728rem, 1.4604rem + 1.3381vi, 2.6647rem);
  --primary-50: #FAFAFA;
  --primary-400: #a1a1aa;
  --primary-600: #52525b;
  --primary-700: #3f3f46;
  --primary-800: #27272a;
  --primary-900: #18181b;
  --primary-950: #09090b;
  --accent-300: #fdba74;
  --accent-500: #f97316;
  --accent-950: #431407;
  --error-700: #b91c1c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

svg {
  pointer-events: none;
  fill: var(--primary-50);
  width: auto;
  height: 1.5rem;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

body {
  background-color: var(--primary-900);
  color: var(--primary-50);
  font-family: 'Jost', sans-serif;
  font-size: var(--fs-100);
}

#app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  min-height: 100vh;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

main {
  padding-inline: 1rem;
  justify-self: center;
  width: min(100%, 72.5rem);
}

#app>*+*:not(.modal),
main>section+section {
  margin-block-start: clamp(2rem, 8%, 4rem);
}

main>*+* {
  margin-block-start: 1.5rem;
}

.checkout>*+*,
.single-product .quantity>*+*,
.product-details>*+*,
footer>*+*,
footer nav *+*,
section>*+* {
  margin-block-start: 1rem;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.single-product>*+* {
  margin: 0;
}

/*Headings and text*/

h1 {
  font-size: var(--fs-400);
  line-height: 1.1;
}

h2 {
  font-size: var(--fs-200);
  font-weight: 600;
}

p {
  line-height: 1.5;
}

/*Header*/

header>nav {
  background-color: var(--primary-950);
  padding: 12px;
  border-top: 4px solid var(--accent-500);
}

header .wrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 8px;
  flex-wrap: wrap;
}

header nav ul {
  display: flex;
  gap: 24px;
  overflow: auto;
}

header nav ul li:first-child {
  margin-left: auto;
}

header nav ul li:last-child {
  margin-right: auto;
}

header nav ul li {
  flex: 0 0 auto;
  padding: 4px;
}

a.logo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: auto;
  font-size: var(--fs-200);
}

.logo svg {
  height: calc(1.5rem + 1vw);
}

a.cart svg,
a.login svg {
  margin: 0.5rem;
  height: 2rem;
  fill: var(--accent-500);
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

form>div {
  display: flex;
}

a.cart {
  position: relative;
}

.number-objects {
  color: var(--primary-900);
  background-color: var(--primary-50);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: 3px solid var(--primary-900);
  top: -20%;
  right: -15%;
  transition: transform 0.4s ease-out;
}

a.cart svg {
  fill: var(--accent-500);
}

#search {
  border-radius: 12px 0 0 12px;
  padding: 8px;
  width: 100%;
}

form button {
  border-radius: 0 12px 12px 0;
  background-color: var(--accent-500);
  border: 0;
}

form button svg {
  fill: var(--accent-950);
}

/*Breadcrumbs*/

.breadcrumb {
  width: min(100%, 1160px);
  font-size: var(--fs-50);
  margin-inline: auto;
  padding-left: 16px;
}

#app .breadcrumb+main {
  margin-block-start: 1.5rem;
}

.breadcrumb ol {
  display: flex;
  gap: 8px;
}

ol li a,
.notice a {
  text-decoration: underline;
}

li {
  display: flex;
  align-items: center;
  gap: 8px;
}

li svg {
  fill: var(--primary-400);
  height: 1rem;
}

/*Homepage categories*/

.category.scroll {
  grid-auto-flow: column;
  grid-auto-columns: 192px;
  overflow-x: auto;
}

.category {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  gap: 12px;
}

.category>article {
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.category>article a {
  margin-top: 12px;
}

.category>article a::after {
  content: "";
  position: absolute;
  inset: 0;
}

.category article>img {
  align-self: center;
  max-height: 168px;
}

.category article p,
.price {
  font-size: var(--fs-300);
}

.category article p>span,
.price span {
  font-size: var(--fs-100);
  margin-left: 4px;
}

/*Error message*/

.warning {
  display: flex;
  gap: 4px;
  font-size: 1rem;
}

.price+.warning,
.checkout .warning {
  font-weight: 600;
}

.warning svg {
  flex-shrink: 0;
}

/*Single product page*/

.single-product {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: clamp(1rem, 3vmax, 1.5rem);
}

.product-details {
  flex-grow: 2;
  flex-basis: 250px;
}

.single-product img {
  border-radius: 5px;
}

.single-product h1 {
  display: block;
}

.img-container>button {
  cursor: zoom-in;
  margin-bottom: 12px;
}

.img-container button {
  border: 0;
  padding: 0;
  background-color: transparent;
  display: block;
}

.img-container button:hover,
.img-container button:active {
  background-color: transparent;
}

.img-container {
  border: 2px solid var(--primary-700);
  padding: 16px;
  border-radius: 10px;
  flex-basis: 384px;
}

.thumbnails {
  display: flex;
  justify-content: space-evenly;
  gap: 12px;
}

.thumbnails img {
  object-fit: cover;
}

.thumbnails img,
.thumbnails button {
  height: clamp(64px, 4rem + 2vw, 96px);
  width: clamp(64px, 4rem + 2vw, 96px);
}

.single-product .quantity,
.checkout {
  padding: 16px;
  border-radius: 10px;
  background-color: var(--primary-700);
  flex: 1 1 150px;
}

.single-product .quantity>div {
  display: flex;
  align-items: center;
  gap: 8px;
}

/*Links and hover, active, focus states of elements*/

a {
  color: var(--primary-50);
}

@media (hover: hover) {

  a:hover {
    color: var(--accent-500);
  }

  a:hover svg,
  .quantity button:hover svg {
    fill: var(--accent-950);
  }

  article a:hover {
    text-decoration: underline;
  }

  a.login:hover,
  a.cart:hover,
  button:hover,
  .quantity button.cart:hover {
    background-color: var(--accent-300);
    border-color: var(--accent-300);
    color: var(--accent-950);
  }

  a.logo:hover svg,
  .close:hover svg,
  footer a:hover svg {
    fill: var(--accent-500);
  }

  a.cart:hover .number-objects {
    transform: scale(1.2);
  }

  .quantity button:hover {
    background-color: var(--accent-500);
    border-color: var(--accent-500);
  }

  .close:hover {
    background-color: transparent;
  }

  .arrow:hover {
    background-color: var(--accent-500);
    border-color: var(--accent-900);
  }

  .quantity button:disabled:hover,
  .quantity .cart:disabled:active {
    cursor: auto;
    color: var(--primary-800);
    border-color: var(--primary-800);
    background-color: transparent;
  }

  .quantity button:disabled:hover svg,
  .quantity .cart:disabled:active svg {
    fill: var(--primary-800);
  }

  @media screen and (max-width:50rem) {

    a.login:hover,
    a.cart:hover {
      background-color: transparent;
    }

    a.login:hover svg,
    a.cart:hover svg {
      fill: var(--accent-300);
    }
  }
}

a:active {
  color: var(--accent-500);
}

a:active svg,
.quantity button:active svg {
  fill: var(--accent-950);
}

a.logo:active svg,
footer a:active svg {
  fill: var(--accent-500);
}

article a:active {
  text-decoration: underline;
}

a.login:active,
a.cart:active,
button:active,
.quantity button.cart:active,
.checkout button:active {
  background-color: var(--accent-300);
  border-color: var(--accent-300);
  color: var(--accent-950);
}

a.logo:active svg,
.close:active svg {
  fill: var(--accent-500);
}

a.cart:active .number-objects {
  transform: scale(1.2);
}

.quantity button:active {
  background-color: var(--accent-500);
  border-color: var(--accent-500);
}

.close:active {
  background-color: transparent;
}

.arrow:active {
  background-color: var(--accent-500);
  border-color: var(--accent-900);
}

@media screen and (max-width:49.94rem) {

  a.login:active,
  a.cart:active {
    background-color: transparent;
  }

  a.login:active svg,
  a.cart:active svg {
    fill: var(--accent-300);
  }
}

*:focus {
  outline: none;
  box-shadow: 0 0 0 3px #22d3ee;
}

@supports selector(:focus-visible) {

  *:focus {
    outline: 0;
    box-shadow: none;
  }

  *:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px #22d3ee;
  }
}

/*Cart*/

.cart-checkout {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
}

.cart-checkout>div {
  flex-grow: 3;
}

.cart-checkout .checkout {
  flex-grow: 1;
  flex-basis: 200px;
}

.empty {
  display: block;
  text-align: center;
}

.empty+a {
  font-size: var(--fs-200);
  text-align: center;
  display: block;
}

.cartProduct {
  border-bottom: 1px solid var(--primary-800);
  padding: 24px 0;
}

.cartProduct .quantity button {
  height: 2.5rem;
  font-size: var(--fs-100);
}

.checkout button {
  font-size: var(--fs-200);
  background-color: var(--accent-500);
  color: var(--accent-950);
  border: 0;
  padding: 8px 16px;
  width: 100%;
}

.cartProduct h2 {
  display: block;
  margin-bottom: 12px;
}

.cartProduct div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.checkout p:last-of-type {
  font-size: var(--fs-300);
}

.cartProduct div>img {
  max-height: 96px;
  margin: 0 24px;
}

.cartProduct .quantity {
  gap: 8px;
}

.cartProduct .price {
  margin-inline-end: auto;
}

/*Buttons and inputs*/

button,
input,
select {
  font-family: inherit;
  font-size: inherit;
  border: 0;
  border-radius: 10px;
  padding: 8px;
}

button {
  font-size: var(--fs-200);
  cursor: pointer;
  color: var(--primary-50);
  border: 2px solid var(--primary-50);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.quantity button:disabled {
  cursor: auto;
  color: var(--primary-800);
  border-color: var(--primary-800);
  background-color: transparent;
}

.quantity button:disabled svg {
  fill: var(--primary-800);
}

input:not(:focus):invalid {
  outline: 3px solid var(--accent-500);
}

input[type="number"],
button[value="plus"],
button[value="minus"] {
  width: 2.5rem;
  height: 2.5rem;
}

input[type="number"] {
  appearance: textfield;
  text-align: center;
  overflow: visible;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button.cart {
  width: 100%;
  background-color: var(--accent-500);
  border-color: var(--accent-500);
  color: var(--accent-950);
}

button.cart svg {
  fill: var(--accent-950);
}

.sort-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.sort-container select {
  background-color: var(--primary-50);
  color: var(--primary-900);
}

/*Modal*/

.modal {
  background: var(--primary-900);
  width: 100%;
  height: 100%;
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}

#dialog-title {
  font-size: var(--fs-100);
  font-weight: 400;
}

.modal>div img {
  border-radius: 30px;
  max-height: 768px;
}

.modal>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
}

.modal>div:first-of-type {
  z-index: 3;
  background-color: var(--primary-900);
}

.modal>div:nth-of-type(2) {
  position: absolute;
  top: 50%;
  z-index: 3;
}

.modal>div:last-of-type {
  justify-content: center;
  height: 100%;
  padding: 0 36px;
  overflow: auto;
}

.close {
  border: 0;
}

.close svg {
  height: 2.5rem;
}

.arrow {
  background-color: var(--primary-50);
  border-color: var(--primary-900);
}

.arrow svg {
  fill: var(--primary-900);
}

/*Footer*/

footer {
  background-color: var(--primary-950);
  padding: 24px 0 0 0;
}

footer .wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  gap: 48px;
}

footer .wrapper>section {
  flex-grow: 1;
  flex-basis: calc((50rem - 100%)*999);
}

footer h2 {
  display: block;
  font-weight: 600;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--primary-50);
}

footer .logo {
  font-size: var(--fs-200);
  display: flex;
  align-items: center;
  gap: 8px;
}

footer a svg {
  margin-right: 12px;
}

.notice,
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: var(--fs-50);
}

.notice {
  margin-block-start: 2rem;
}

.info {
  background-color: var(--primary-900);
  padding: 12px;
}

.info svg {
  fill: var(--primary-50);
}

/*Media queries*/

@media screen and (min-width: 50rem) {

  .category.scroll {
    grid-auto-flow: row;
  }

  h1,
  h2 {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5em;
  }

  h1::after,
  h2::after {
    content: "";
    border-top: 2px solid var(--primary-50);
    align-self: center;
  }

  a.login,
  a.cart {
    padding: 8px 16px;
    border-radius: 10px;
    border: 2px solid var(--accent-500);
  }

  a.cart svg,
  a.login svg {
    margin: 0;
  }

  a.login svg {
    fill: var(--accent-950);
  }

  a.login {
    color: var(--accent-950);
    background-color: var(--accent-500);
    font-weight: 700;
  }

  a.cart {
    color: var(--accent-500);
  }

  .cart,
  .login {
    display: flex;
    align-items: center;
    gap: 8px;
  }

}

@media screen and (min-width: 59.375rem) {

  form {
    order: -1;
    margin-inline-end: auto;
    width: 384px;
  }

  header .wrapper .logo {
    order: -1;
    margin-inline-end: 16px;
  }

  header .wrapper {
    align-items: flex-start;
  }

}

@media (hover:none) {

  header nav ul::-webkit-scrollbar,
  .scroll-box::-webkit-scrollbar {
    display: none;
  }

  header nav ul,
  .scroll-box {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/*Animations*/
@media screen and (prefers-reduced-motion:no-preference) {

  .number-objects {
    animation: scale-in-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }

  @keyframes scale-in-center {
    0% {
      transform: scale(0);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}